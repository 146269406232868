<template>
  <header class="nav-bar">
    <!-- NAVBAR INTERNAL -->
    <div class="nav-bar__internal">
      <!-- LOGO -->
      <nuxt-link class="nav-bar__logo-wrapper relative" to="/">
        <img class="nav-bar__logo" src="/nota_logo.svg" alt="logo" />
      </nuxt-link>
      <!-- SEARCH INPUT -->
      <search-input @search="search" class="hidden lg:block" />
      <!-- LINKS CONTAINER -->
      <nav class="nav-bar__links-container">
        <nuxt-link to="/explore" class="nav-bar__link relative"
          >Explore</nuxt-link
        >
        <nuxt-link
          v-if="enableEvents"
          to="/events/upcoming"
          class="nav-bar__link relative"
          >Events</nuxt-link
        >
        <div v-else>
          <nuxt-link class="nav-bar__link_disabled relative">Events</nuxt-link>
          <div
            class="absolute -translate-y-9 -translate-x-4 animate-pulse text-xs text-nota-pink"
          >
            COMING SOON
          </div>
        </div>

        <nuxt-link to="/families" class="nav-bar__link"
          >Families
          <!--
          <div
            class="absolute -translate-y-10 translate-x-14 animate-pulse text-sm italic text-nota-pink"
          >
            NEW
          </div>
          -->
        </nuxt-link>
        <nuxt-link to="/create" class="nav-bar__link">Create</nuxt-link>
        <nuxt-link to="/careers" class="nav-bar__link">Careers</nuxt-link>
        <!-- <nuxt-link class="nav-bar__link">Docs</nuxt-link> -->
        <nota-button
          class="w-32 text-center"
          bg="#00D568"
          v-if="!connected"
          @click="$connect"
        >
          Connect
        </nota-button>
        <div v-else class="flex gap-2">
          <nota-button
            class="w-28 text-center"
            @click="router.push('/user/' + address)"
          >
            My Profile
          </nota-button>
          <nota-button
            class="w-32 text-center"
            bg="#FF0000"
            @click="$disconnect"
          >
            Disconnect
          </nota-button>
        </div>
      </nav>
      <button @click="open = true" class="nav-bar__menu-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-10 w-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>
    <layout-nav-menu />
  </header>
</template>

<script setup>
  const open = useNavToggle();
  const connected = useConnected();
  const address = useAddress();
  const router = useRouter();
  const search = (s) => {
    router.push("/explore/?q=" + encodeURIComponent(s));
  };
  const { $preconnect, $api } = useNuxtApp();
  const enableEvents = ref(false);

  onBeforeMount(async () => {
    $preconnect();
    const events = await $api.getCollections({
      upcoming: true,
    });
    if (events.length > 0) {
      console.log("Events found");
      enableEvents.value = true;
    }
  });
</script>

<style lang="postcss" scoped>
  @import "../../assets/css/tailwind.css";

  .menu-dropdown {
    @apply absolute bg-ivory px-3 pt-7 pb-2;
  }

  .nav-bar {
    @apply flex items-center justify-center bg-deep-blue py-7 px-4 xl:px-0;

    &__internal {
      @apply flex w-full max-w-7xl items-center space-x-12;
    }

    &__logo-wrapper {
      @apply mr-auto;
    }

    &__links-container {
      @apply hidden items-center space-x-10 lg:flex;
    }

    &__link {
      @apply text-center text-sm font-medium text-ivory;
    }

    &__link:hover {
      color: rgb(255 255 255 / 40%);
    }

    &__menu-button {
      @apply p-2 text-ivory lg:hidden;
    }

    &__search-input-gradient-outline {
      @apply relative hidden rounded-full bg-gradient-to-br from-nota-light via-white to-nota-light p-0.5;
    }

    &__search-input-gradient-outline_disabled {
      @apply relative cursor-not-allowed rounded-full from-nota-light via-white to-nota-light p-0.5;
    }

    &__field {
      @apply my-auto h-10  rounded-full bg-nota-dark pl-11 text-sm text-white disabled:cursor-not-allowed disabled:bg-slate-500 disabled:text-gray-400;
    }

    &__search-input {
      @apply absolute top-1.5 left-1.5 z-10 my-auto h-8 w-8 rounded-full bg-nota-light p-1 disabled:bg-gray-500;
    }

    &__search-input--disabled {
      @apply absolute top-1.5 left-1.5 z-10 my-auto h-8 w-8 rounded-full  bg-gray-500 p-1;
    }

    &__logo {
      @apply h-11;
    }

    &__link {
      @apply bg-gradient-to-br text-center text-lg font-medium text-ivory;
    }

    &__link_disabled {
      @apply cursor-not-allowed text-center text-lg font-medium text-gray-400;
    }
  }
</style>
