<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <template>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-50" @close="open = false">
        <div class="fixed inset-0" />

        <div class="fixed inset-0 overflow-hidden bg-deep-blue/70">
          <div class="absolute inset-0 overflow-hidden">
            <div
              class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
            >
              <TransitionChild
                as="template"
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enter-from="translate-x-full"
                enter-to="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leave-from="translate-x-0"
                leave-to="translate-x-full"
              >
                <DialogPanel class="pointer-events-auto w-screen max-w-md">
                  <div
                    class="flex h-full flex-col overflow-y-scroll bg-deep-blue py-6 shadow-xl"
                  >
                    <div class="px-4 sm:px-6">
                      <div class="flex items-start justify-start">
                        <div class="flex h-7 items-center">
                          <button
                            type="button"
                            class="rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none"
                            @click="open = false"
                          >
                            <span class="sr-only">Close panel</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              class="h-8 w-8 fill-current text-nota-very-light"
                              viewBox="0 0 50 50"
                              fill="fillCurrent"
                            >
                              <path
                                d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="relative mt-8 flex-1 px-4 sm:px-6">
                      <!-- Replace with your content -->
                      <search-input @search="search" />
                      <div class="mt-6 flex h-full flex-col space-y-4">
                        <!-- <nuxt-link to="/events/upcoming" class="nav-menu__item"> Events </nuxt-link> -->
                        <nuxt-link to="/explore" class="nav-menu__item relative"
                          >Explore</nuxt-link
                        >
                        <nuxt-link
                          v-if="enableEvents"
                          to="/events/upcoming"
                          class="nav-menu__item relative"
                          @click="open = false"
                          >Events</nuxt-link
                        >
                        <div v-else>
                          <nuxt-link class="nav-menu__item_disabled relative"
                            >Events</nuxt-link
                          >
                          <div
                            class="absolute -translate-y-8 translate-x-20 animate-pulse text-xs text-nota-pink"
                          >
                            COMING SOON
                          </div>
                        </div>
                        <nuxt-link
                          to="/families"
                          class="nav-menu__item"
                          @click="open = false"
                          >Families
                          <!--
                          <div
                            class="absolute -translate-y-11 translate-x-20 animate-pulse text-sm italic text-nota-pink"
                          >
                            NEW
                          </div>--></nuxt-link
                        >
                        <nuxt-link
                          to="/create"
                          class="nav-menu__item"
                          @click="open = false"
                          >Create</nuxt-link
                        >
                        <!-- <nuxt-link class="nav-menu__item"> Docs </nuxt-link> -->
                        <div class="pt-1">
                          <nota-button
                            class="w-32 text-center"
                            v-if="!connected"
                            @click="
                              open = false;
                              $connect();
                            "
                            bg="#00D568"
                          >
                            Connect
                          </nota-button>
                          <div v-else class="flex flex-col gap-2">
                            <nota-button
                              class="w-32 text-center"
                              @click="
                                open = false;
                                router.push('/user/' + address);
                              "
                            >
                              My Profile
                            </nota-button>
                            <nota-button
                              class="w-32 text-center"
                              bg="#FF0000"
                              @click="
                                open = false;
                                $disconnect();
                              "
                            >
                              Disconnect
                            </nota-button>
                          </div>
                        </div>
                      </div>
                      <!-- /End replace -->
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
</template>

<script setup>
  import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from "@headlessui/vue";
  const showDropdown = ref(false);
  const router = useRouter();
  const searchText = ref("");
  const address = useAddress();
  const open = useNavToggle();
  const connected = useConnected();
  const { $preconnect, $api } = useNuxtApp();
  const enableEvents = ref(false);

  const search = () => {
    open.value = false;
    router.push("/explore/?q=" + encodeURIComponent(searchText.value));
  };

  onBeforeMount(async () => {
    $preconnect();
    const events = await $api.getCollections({
      upcoming: true,
    });
    if (events.length > 0) {
      console.log("Events found");
      enableEvents.value = true;
    }
  });
</script>

<style lang="postcss" scoped>
  .menu-dropdown {
    @apply absolute bg-ivory px-3 pt-7 pb-2;
  }

  .nav-menu {
    &__item {
      @apply text-2xl font-bold text-ivory;
    }
    &__item_disabled {
      @apply cursor-not-allowed text-2xl font-bold text-gray-400;
    }
    &__item:hover {
      color: rgb(255 255 255 / 40%);
    }
  }

  /* SEARCH INPUT */
  .search-input {
    @apply nota-gradient relative mb-4 block rounded-full p-0.5;

    &__field {
      @apply my-auto h-10  rounded-full bg-nota-dark pl-11 text-sm text-white;
    }

    &__icon {
      @apply absolute  top-1.5 left-1.5 z-10 my-auto h-8 w-8 rounded-full bg-nota-light p-1;
    }
  }
</style>
