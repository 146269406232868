<template>
  <div class="flex items-center bg-ivory py-2">
    <div class="relative mx-auto w-full max-w-5xl px-4 xl:px-0">
      <!-- middle section with contract address -->
      <div align="center">
        <nota-alert
          class="drop-shadow-2xl"
          v-model="alert"
          :message="alertMessage"
        />
        <div
          class="hidden cursor-pointer hover:text-blue-400 lg:block"
          @click="copy"
        >
          <span
            >eNFT CONTRACT ADDRESS:
            {{
              nftAddress.substring(0, 10) + "..." + nftAddress.substring(34, 42)
            }}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            class="ml-1 inline h-5 w-5 fill-current"
            viewBox="0 0 50 50"
            @click="copy"
          >
            <path
              d="M 19 0 L 19 6 L 21 8 L 21 2 L 36 2 L 36 14 L 48 14 L 48 40 L 33 40 L 33 42 L 50 42 L 50 12.59375 L 37.40625 0 Z M 38 3.40625 L 46.59375 12 L 38 12 Z M 0 8 L 0 50 L 31 50 L 31 20.59375 L 30.71875 20.28125 L 18.71875 8.28125 L 18.40625 8 Z M 2 10 L 17 10 L 17 22 L 29 22 L 29 48 L 2 48 Z M 19 11.4375 L 27.5625 20 L 19 20 Z"
            ></path>
          </svg>
        </div>
      </div>
      <div
        style="top: 0px; right: 0px"
        class="flex cursor-pointer lg:absolute"
        @click="buyNBL"
      >
        <span class="mr-4 uppercase">BUY NBL</span>
        <img src="/pcs.svg" />
      </div>
    </div>
  </div>
</template>

<script setup>
  const config = useRuntimeConfig();
  const nftAddress = config.public.nft;
  const alert = ref(false);
  const alertMessage = ref("");
  const copy = () => {
    const textArea = document.createElement("textarea");
    textArea.width = "1px";
    textArea.height = "1px";
    textArea.background = "transparent";
    textArea.value = config.public.nft;
    document.body.append(textArea);
    textArea.select();
    navigator.clipboard.writeText(textArea.value);
    //document.execCommand("copy");
    document.body.removeChild(textArea);
    showAlert("Contract address copied!");
  };
  const buyNBL = () => {
    window.open(
      "https://pancakeswap.finance/swap?outputCurrency=0xFAA0fC7B803919B091dBe5FF709b2dAbb61b93d9&inputCurrency=0x55d398326f99059fF775485246999027B3197955",
      "_blank"
    );
  };

  const showAlert = (message) => {
    alertMessage.value = message;
    alert.value = true;
  };
</script>
