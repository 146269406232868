<template>
  <div class="relative">
    <input
      class="search-input__field"
      style="border: 1px solid gray; outline: none;"
      type="text"
      placeholder="SEARCH"
      v-model="search"
      @keydown.enter="emit('search', search)"
    />
    <div class="absolute top-3 left-4 z-10 my-auto h-14 w-14 cursor-pointer" @click="emit('search', search)">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.17544 0C9.58604 0 12.3509 2.76484 12.3509 6.17544C12.3509 7.57706 11.8839 8.86962 11.0971 9.90604L15.7534 14.5624C16.0822 14.8913 16.0822 15.4245 15.7534 15.7534C15.4544 16.0523 14.9866 16.0795 14.6569 15.8349L14.5624 15.7534L9.90604 11.0971C8.86962 11.8839 7.57706 12.3509 6.17544 12.3509C2.76484 12.3509 0 9.58604 0 6.17544C0 2.76484 2.76484 0 6.17544 0ZM6.17544 1.68421C3.695 1.68421 1.68421 3.695 1.68421 6.17544C1.68421 8.65587 3.695 10.6667 6.17544 10.6667C8.65587 10.6667 10.6667 8.65587 10.6667 6.17544C10.6667 3.695 8.65587 1.68421 6.17544 1.68421Z" fill="#FBF9F8" fill-opacity="0.5"/>
      </svg>
    </div>
  </div>
</template>
<script setup>
const search = ref("")
const emit = defineEmits(['search'])
</script>
<style lang="postcss" sacoped>
/* SEARCH INPUT */
.search-input__field {
  @apply my-auto h-10 rounded-full bg-deep-blue pl-11 py-2 text-sm text-white;
}
</style>
